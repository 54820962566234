<template>
  <div class="paylater-list pay-later-product-admin">
    <vx-card class="accordin-card">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Product details <span class="mid-blue text-xl align-top">*</span></h3>
          </div>

          <div class="flex mb-8">
            <div class="input-grp">
              <label for="productName" class="w-full text-xm font-normal">Product name <span class="required-text">*</span></label>
              <vs-input size="large" v-model="product.productName" data-vv-validate-on="blur" data-vv-as="product name" v-validate="'required|max:100'" name="productName" id="productName" />
              <span class="text-danger text-sm" v-show="errors.has('productName')">{{ errors.first("productName") }}</span>
            </div>
            <div class="input-grp">
              <label for="settlementAccounts" class="w-full text-xm font-normal">Settlement account <span class="required-text">*</span></label>
              <vs-select v-model="product.settlementAccount" id="settlementAccounts" name="settlementAccounts" class="w-12/12" data-vv-as="Settlement Account" data-vv-validate-on="blur" v-validate="'required'">
                <vs-select-item :key="index" :value="item._id" :text="item.displayName" v-for="(item, index) in settlementAccounts"/>
              </vs-select>
              <span class="text-danger text-sm" v-show="errors.has('settlementAccounts')">{{ errors.first("settlementAccounts") }}</span>
            </div>
          </div>

          <div class="flex mb-8">
            <div class="input-grp">
              <label for="productDescription" class="w-full text-xm font-normal flex">
                Product description <span class="required-text pl-2">*</span>
                <vs-tooltip text="Please enter brief description for this product. This description will be presented to merchant.">
                  <img :src="questionIcon" class="pl-2" />
                </vs-tooltip>
              </label>
              <vs-textarea
                size="large"
                v-model="product.productDescription"
                data-vv-validate-on="blur"
                data-vv-as="product description"
                v-validate="'required|max:100'"
                name="productDescription"
                id="productDescription"
                class="custom"
              />
              <span class="text-danger text-sm block py-3" v-show="errors.has('productDescription')">{{errors.first("productDescription")}}</span>
            </div>

            <div class="input-grp" v-if="['B2C_BNPO', 'B2C_BNPO_NC'].includes(this.$route.query.productType)">
              <label for="productDescription" class="w-full text-xm font-normal flex">
                Product text <span class="required-text pl-2">*</span>
                <vs-tooltip text="Please enter brief description for this product. This description will be displayed to customer on payment page.">
                  <img :src="questionIcon" class="pl-2" />
                </vs-tooltip>
              </label>
              <vs-textarea
                size="large"
                v-model="product.productText"
                data-vv-validate-on="blur"
                data-vv-as="product text"
                v-validate="['B2C_BNPO', 'B2C_BNPO_NC'].includes(this.$route.query.productType) ? 'required||max:100' : ''"
                name="productText"
                id="productText"
                class="custom"
              />
              <span class="text-danger text-sm block py-3" v-show="errors.has('productText')">{{ errors.first("productText") }}</span>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5" v-if="['B2C_BNPO', 'B2C_BNPO_NC'].includes(this.$route.query.productType)">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Product configuration</h3>
          </div>
          <vs-row>
            <vs-col class="lg:mr-5 md:mr-5 w-auto">
              <div class="radio-card h-full" @click="changeProductConfig('THREE_PARTY')" :class="{ active: product.productConfigType === 'THREE_PARTY' }">
                <vs-row class="justify-between rounded-b-lg">
                  <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">3-party product</vs-col>
                  <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex justify-center margin-break">
                    <vs-radio v-model="product.productConfigType" vs-value="THREE_PARTY" vs-name="productConfigType" name="productConfigType" id="productConfigType" :disabled="productEdit"></vs-radio>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>
            <vs-col class="w-auto">
              <div class="radio-card h-full" @click="changeProductConfig('TWO_PARTY')" :class="{ active: product.productConfigType === 'TWO_PARTY' }">
                <vs-row class="justify-between rounded-b-lg">
                  <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">2-party product</vs-col>
                  <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex justify-center margin-break">
                    <vs-radio v-model="product.productConfigType" vs-value="TWO_PARTY" vs-name="productConfigType" name="productConfigType" id="productConfigType" :disabled="productEdit"></vs-radio>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>
          </vs-row>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Amount limits <span class="mid-blue text-xl align-top">*</span></h3>
          </div>
          <div class="flex">
            <div class="input-grp">
              <label for="minimumAmount" class="w-full text-xm font-normal">Minimum amount <span class="required-text">*</span></label>
              <money
                name="minimumAmount"
                id="minimumAmount"
                v-bind="money2"
                v-validate="'required||min_value:1'"
                v-model="product.minimumAmount"
                data-vv-as="minimum amount"
              ></money>
              <span class="text-danger text-sm block py-3" v-show="errors.has('minimumAmount')">{{ errors.first("minimumAmount") }}</span>
            </div>
            <div class="input-grp">
              <label for="maximumAmount" class="w-full text-xm font-normal">Maximum amount <span class="required-text">*</span></label>
              <money
                name="maximumAmount"
                id="maximumAmount"
                v-bind="money2"
                v-validate="'required||min_value:1||greaterThan'"
                v-model="product.maximumAmount"
                data-vv-as="maximum amount"
              ></money>
              <span class="text-danger text-sm block py-3" v-show="errors.has('maximumAmount')">{{ errors.first("maximumAmount") }}</span>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>
    <vx-card class="accordin-card mt-5" v-if="this.$route.query.productType === 'B2C_BNPL'">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">
              Schedule <span class="mid-blue text-xl align-top">*</span>
            </h3>
          </div>
          <div class="my-8">
            <label for="maxDuration" class="w-full font-light text-base">Max duration (days)</label>
            <p class="block text-base mt-3">{{ product.maxDuration }} days</p>
          </div>

          <div class="flex flex-wrap">
            <div class="input-grp">
              <label for="paymentFrequency" class="text-xm font-normal w-full">Payment frequency</label>
              <div class="flex flex-col gap-y-2">
                <vs-select
                  v-model="product.paymentFrequency"
                  id="paymentFrequency"
                  name="paymentFrequency"
                  class="w-4/12"
                  v-validate="this.$route.query.productType === 'B2C_BNPL' ? 'required' : ''"
                  data-vv-as="payment frequency"
                >
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in frequency"/>
                </vs-select>
                <span class="text-danger text-sm block py-3" v-show="errors.has('paymentFrequency')" >{{ errors.first("paymentFrequency") }}</span>
              </div>
            </div>

            <div class="input-grp">
              <label for="noOfPayments" class="text-xm font-normal w-full">Number of payments</label>
              <div class="flex flex-col">
                <money
                  name="noOfPayments"
                  id="noOfPayments"
                  v-bind="numeric"
                  data-vv-as="no of payments"
                  class="w-full py-3"
                  v-model="product.noOfPayments"
                ></money>
              </div>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5" v-else>
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Schedule <span class="mid-blue text-xl align-top">*</span></h3>
          </div>

          <div class="flex flex-wrap">
            <div class="input-grp">
              <label for="maximumDuration" class="text-xm font-normal w-full">Maximum duration <span class="required-text">*</span></label>
              <p class="block text-base mt-3">{{ product.maxDuration }}</p>
              <span class="text-danger text-sm" v-show="errors.has('maximumDuration')">{{ errors.first("maximumDuration") }}</span>
            </div>
            <div class="input-grp">
              <label for="initialPeriod" class="w-full text-xm font-normal">Initial period <span class="required-text">*</span></label>
              <div class="flex gap-3 items-center">
                <money
                  name="initialPeriod"
                  id="initialPeriod"
                  v-bind="numeric"
                  v-validate="['B2C_BNPO', 'B2C_BNPO_NC'].includes(this.$route.query.productType) ? 'required||min_value:1' : ''"
                  class="vs-input--input"
                  v-model="product.initialPeriod"
                  data-vv-as="initial period"
                ></money>
                <span>days</span>
              </div>
              <span class="text-danger text-sm" v-show="errors.has('initialPeriod')">{{ errors.first("initialPeriod") }}</span>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Payment option <span class="mid-blue text-xl align-top">*</span> </h3>
          </div>
          <vs-row>
            <vs-col class="lg:mr-5 md:mr-5 w-auto">
              <div class="mb-1">
                <p class="mb-2">Auto-debit payment method</p>
                <vs-switch v-model="product.autoDebit" @click="changeDishonourFee" name="autoDebit"></vs-switch>
              </div>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col class="lg:mr-5 md:mr-5 w-auto mt-2">
              <div class="flex">
                <p class="my-4">Payment methods available on payment page</p>
              </div>
              <div class="flex flex-wrap gap-4">
                <div>
                  <div class="selection-card" :class="[{ selected: product.paymentMethods.paymentPage.includes('CARD') }, !product.autoDebit ? 'disabled' : '' ]">
                    <div class="selection-card-header">
                      <div class="selection-option">
                        <vs-checkbox vs-value="CARD" v-model="product.paymentMethods.paymentPage" :class="!product.autoDebit ? 'disabled' : ''" @change="validatePaymentMethods('CARD')">Card</vs-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="selection-card" :class="[{ selected: product.paymentMethods.paymentPage.includes('DIRECT_DEBIT') }, !product.autoDebit ? 'disabled' : '' ]">
                    <div class="selection-card-header">
                      <div class="selection-option">
                        <vs-checkbox vs-value="DIRECT_DEBIT" v-model="product.paymentMethods.paymentPage" :class="!product.autoDebit ? 'disabled' : ''" @change="validatePaymentMethods('DIRECT_DEBIT')">Direct debit</vs-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span class="text-danger text-sm block mt-1" v-show="errors.has('paymentMethod')">{{ errors.first("paymentMethod") }}</span>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col class="lg:mr-5 md:mr-5 w-auto mt-2">
              <div class="flex">
                <p class="my-4">Payment methods available on customer portal</p>
              </div>
              <div class="flex flex-wrap gap-4">
                <div>
                  <div class="selection-card disabled" :class="{ selected: product.paymentMethods.customerPortal.includes('CARD') }">
                    <div class="selection-card-header">
                      <div class="selection-option">
                        <vs-checkbox vs-value="CARD" v-model="product.paymentMethods.customerPortal" class="disabled">Card</vs-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="selection-card disabled" :class="{ selected: product.paymentMethods.customerPortal.includes('DIRECT_DEBIT') }">
                    <div class="selection-card-header">
                      <div class="selection-option">
                        <vs-checkbox vs-value="DIRECT_DEBIT" v-model="product.paymentMethods.customerPortal" class="disabled">Direct debit</vs-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="selection-card" :class="[{ selected: product.paymentMethods.customerPortal.includes('EFT') }, !product.autoDebit ? 'disabled' : '' ]">
                    <div class="selection-card-header">
                      <div class="selection-option">
                        <vs-checkbox vs-value="EFT" v-model="product.paymentMethods.customerPortal" :class="!product.autoDebit ? 'disabled' : ''">EFT</vs-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vs-col>
          </vs-row>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Fees<span class="mid-blue text-xl align-top">*</span></h3>
          </div>
          <div>
            <div class="mb-8">
              <h3 class="text-base pb-2">Service fee</h3>
              <hr />
            </div>
            <vs-row>
              <vs-col class="lg:mr-5 md:mr-5 w-auto">
                <div class="radio-card h-full" @click="product.feeModel = 'MSF_INCLUDED'" :class="{ active: product.feeModel === 'MSF_INCLUDED' }">
                  <vs-row class="justify-between">
                    <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">MSF to merchant</vs-col>
                    <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex justify-center margin-break">
                      <vs-radio v-model="product.feeModel" vs-value="MSF_INCLUDED" vs-name="feeModel" name="feeModel" id="feeModel"></vs-radio>
                    </vs-col>
                  </vs-row>
                  <vs-row>
                    <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                      <span class="radio-info">MSF included in purchase amount</span>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-col>
              <vs-col class="w-auto">
                <div class="radio-card h-full" @click="product.feeModel = 'MSF_ADDED'" :class="{ active: product.feeModel === 'MSF_ADDED' }">
                  <vs-row class="justify-between">
                    <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">MSF to customer</vs-col>
                    <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex justify-center margin-break">
                      <vs-radio v-model="product.feeModel" vs-value="MSF_ADDED" vs-name="feeModel" name="feeModel" id="feeModel"></vs-radio>
                    </vs-col>
                  </vs-row>
                  <vs-row>
                    <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                      <span class="radio-info">MSF added on top of purchase amount</span>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-col>
              <vs-col vs-w="12">
                <span class="text-danger text-sm mt-1" v-show="errors.has('feeModel')">{{ errors.first("feeModel") }}</span>
              </vs-col>
            </vs-row>
          </div>
          <vs-row class="mb-5">
            <div class="input-grp mt-8">
              <label for="msfRate" class="w-full text-xm font-normal">MSF rate (%) <span class="mid-blue text-xl align-top">*</span></label>
              <money
                name="msfRate"
                id="msfRate"
                v-bind="money"
                v-validate="'required||decimalValidation'"
                v-model="product.msf"
                data-vv-as="msf rate"
              ></money>
              <span class="text-danger text-sm py-3" v-show="errors.has('msfRate') && fields.msfRate.touched">{{ errors.first("msfRate") }}</span>
            </div>
            <div class="input-grp mt-8">
              <label for="minimumMsfFeeAmount" class="w-full text-xm font-normal">Minimum fee amount <span class="required-text">*</span></label>
                <money
                  name="minimumMsfFeeAmount"
                  id="minimumMsfFeeAmount"
                  v-bind="money2"
                  class="vs-input--input"
                  v-model="product.minimumMsfFeeAmount"
                  data-vv-validate-on="blur"
                  data-vv-as="minimum fee amount"
                ></money>
                <span class="text-danger text-sm block" v-show="errors.has('minimumMsfFeeAmount')">{{ errors.first("minimumMsfFeeAmount") }}</span>
            </div>
          </vs-row>
          <vs-row v-if="showDishonourBox" class="flex-col">
            <h5 class="h4 mt-5 text-base">Direct debit transaction dishonour fee</h5>
            <hr class="vs-divider mt-0 mb-8">
            <div class="flex mb-8">
              <vs-switch v-model="product.isDishonourFeeActive" @click="changeDishonourFee" class="mr-4" /> Charge dishonour fee
            </div>
            <div v-if="showDishonourFee">
              <label for="dishonourFee" class="w-full text-xm font-normal block">Dishonour fee <span class="mid-blue text-xl align-top">*</span></label>
              <money name="dishonourFee" id="dishonourFee" precision="2" v-bind="money2" v-validate="'required'" v-model="product.dishonourFee" data-vv-as="dishonour fee"></money>
              <p class="text-danger text-sm py-3" v-show="errors.has('dishonourFee')">{{ errors.first("dishonourFee") }}</p>
            </div>
          </vs-row>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <!--------------- custom fields section -------------------------->
    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item ref="productFieldCollapse">
          <div slot="header">
            <h3 class="font-normal">Product fields</h3>
          </div>
          <p>You can create custom form fields to collect information related to this payment request.</p>
          <custom-fields v-if="customFieldBind && planReady" v-on:savedCustomField="pushToCustomFields($event)" :customFieldValue="customFieldList"></custom-fields>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion >
        <vs-collapse-item ref="productExtraConditionCollapse">
          <div slot="header">
            <h3 class="font-normal">Extra conditions</h3>
          </div>

          <p class="mb-10">
            You can apply condition(s) to limit the total amount on a payment
            request based on the value input in a specified product field. You
            can also configure the error message merchant sees when the rule is
            breached.
          </p>

          <div class="w-full my-5" v-for="(value, index) in extraConditionsList" :key="index">
            <div class="extra-condition">
              <div class="flex justify-between">
                <h5 class="text-xl font-normal">Extra condition {{ index + 1 }}</h5>
                <span class="lg:hidden">
                  <trash-2-icon @click="removeExtraCondition(index)" class="cursor-pointer"></trash-2-icon>
                </span>
              </div>

              <vs-divider class="mb-10"></vs-divider>

              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="5" class="mb-4 md:mb-8">
                  <label class="w-full text-base font-light">Select extra condition to be applied</label>
                  <vs-select v-model="value.criteria" id="extraConditionCriteria" :name="'extraConditionCriteria.'+index" class="w-full" data-vv-as="extra condition" v-validate="'required'">
                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in extraConditionsCriteria(value.criteria)"/>
                  </vs-select>
                  <span class="text-danger text-sm block py-3" v-show="errors.has('extraConditionCriteria.'+index)">{{ errors.first('extraConditionCriteria.'+index) }}</span>
                </vs-col>
                <vs-col vs-w="12" vs-sm="12" vs-lg="5" class="mb-4 md:mb-8">
                  <label for="extraConditionCriteriaProductField" class="w-full flex text-base font-light">Product field <span class="required-text">*</span>
                    <popper
                      v-if="value.criteria == 'PRODUCT_FIELD_AS_DUE_DATE'"
                      trigger="hover"
                      :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '100px, 2px' } }
                      }">
                      <div class="popper text-left py-3 px-4 w-64">
                        <p class="text-xs text-grey">Products fields must meet the following criteria to be set as due date:</p>
                        <ul class="text-xs list-disc list-outside ml-5 mb-1 text-grey">
                          <li>date field</li>
                          <li>marked as required to send request</li>
                        </ul>
                      </div>
                      <span slot="reference"><img :src="questionIcon" class="pl-2 cursor-pointer"/></span>
                    </popper>
                  </label>
                  <vs-select v-model="value.productField" id="extraConditionProductField" :name="'extraConditionCriteriaProductField.'+index" class="w-12/12" data-vv-as="product field" v-validate="'required'">
                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in productFieldsForExtraConditions(value.criteria)"/>
                  </vs-select>
                  <span class="text-danger text-sm block py-3" v-show="errors.has('extraConditionCriteriaProductField.'+index)">{{ errors.first('extraConditionCriteriaProductField.'+index) }}</span>
                </vs-col>
                <vs-col class="hidden lg:flex" vs-w="12" vs-sm="2" vs-lg="2" vs-type="flex" vs-justify="end" vs-align="start">
                  <trash-2-icon @click="removeExtraCondition(index)" class="cursor-pointer"></trash-2-icon>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="5" class="mb-4">
                  <div class="input-grp relative" >
                    <div v-if="value.criteria == 'MAX_PERCENT_OF_PRODUCT_FIELD'">
                      <label for="extraConditionMaxPercent" class="w-full text-base block">Maximum % <span class="required-text">*</span></label>
                      <money
                        name="extraConditionMaxPercent"
                        id="extraConditionMaxPercent"
                        v-bind="money"
                        v-validate="'required||decimalValidation'"
                        class="vs-input--input w-full"
                        v-model="value.maxPercentage"
                        data-vv-as="extra condition max percent"
                      ></money>
                      <span class="text-danger text-sm block" v-show="errors.has('extraConditionMaxPercent')">{{ errors.first("extraConditionMaxPercent") }}</span>
                    </div>
                    <div v-if="value.criteria == 'PRODUCT_FIELD_AS_DUE_DATE'">
                      <label class="w-full text-base font-light">Override date with max product duration</label>
                      <vs-switch v-model="value.overrideDate" name="overrideDate" />
                    </div>
                  </div>
                </vs-col>
                <vs-col vs-w="12" vs-sm="12" vs-lg="5" class="mb-4">
                  <div class="" v-if="!value.overrideDate">
                    <label for="extraConditionErrorMsg" class="w-full text-base font-light">Error message</label>
                    <vs-textarea
                      :name="'extraConditionErrorMsg.'+index"
                      id="extraConditionErrorMsg"
                      v-model="value.errorMsg"
                      counter="100"
                      data-vv-as="extra condition error message"
                      v-validate="'required||max:100'"
                    ></vs-textarea>
                    <span class="text-danger text-sm block py-3" v-show="errors.has('extraConditionErrorMsg.'+index)">{{ errors.first('extraConditionErrorMsg.'+index) }}</span>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>
          <vs-button v-if="showAddExtraConditionOption" v-round size="medium" class="mt-4 flex items-center justify-center only-border-btn" @click="addExtraCondition">
            <plus-icon size="1.5x" class="button-icon"></plus-icon> Add condition
          </vs-button>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5" v-if="isBNPO">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">
              Auto direct debit days <span class="mid-blue text-xl align-top">*</span>
            </h3>
          </div>
          <div class="flex">
            <div class="input-grp">
              <label for="minimumAmount" class="w-full text-xm font-normal">Number of days after due date that a DD will occur <span class="required-text">*</span></label>
              <money
                name="gracePeriod"
                id="gracePeriod"
                v-bind="money3"
                v-validate="'required||min_value:0'"
                v-model="product.gracePeriod"
                data-vv-as="day"
              ></money>
              <span class="text-danger text-sm block py-3" v-show="errors.has('gracePeriod')">{{ errors.first("gracePeriod") }}</span>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

   <!-- Manual Assessment Block -->
    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Product-based manual assessment</h3>
          </div>
          <div class="">
            <div class="flex">
              <vs-switch v-model="product.isManualAssessment" size="medium"/>
              <p class="ml-6">All requests required to be manually assessed</p>
            </div>
            <div class="mt-8" v-if="product.isManualAssessment">
              <p class="mb-5">Please enter the email address for receiving manual assessment notifications for this product.</p>
              <label for="msfRate" class="w-full mb-0 text-base">Email address</label>
              <vs-input
                size="large"
                v-model="product.manualAssessmentEmail"
                data-vv-as="email"
                v-validate="product.isManualAssessment == true ? 'email' : ''"
                name="manualAssessmentEmail"
                placeholder="Optional"
              />
              <span class="text-danger text-sm py-3" v-show="errors.has('manualAssessmentEmail')">{{ errors.first("manualAssessmentEmail") }}</span>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>
    <!-- Manual Assessment Block Ends -->

    <!-- Criteria-Based Manual Assessment Block -->
    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">

            <h3 class="font-normal">Criteria-based manual assessment</h3>
          </div>
          <div>

            <div class="pb-4">
              <p class="mb-5">Please enter the email address for receiving manual assessment notifications for this product.</p>
              <label for="msfRate" class="w-full mb-0 text-base">Email address</label>
              <vs-input
                size="large"
                v-model="product.criteriaAssessmentEmail"
                data-vv-as="email"
                v-validate="product.criteriaAssessmentEmail == true ? 'email' : ''"
                name="criteriaAssessmentEmail"
                placeholder="Optional"
              />
              <span class="text-danger text-sm py-3" v-show="errors.has('criteriaAssessmentEmail')">{{ errors.first("criteriaAssessmentEmail") }}</span>
            </div>
            <hr class="my-4">
            <div class="mt-6">
              <vs-row v-for="(criteria, index) in manualAssessmentCriteria" :key="index">
                <vs-col vs-w="11" vs-lg="4" vs-md="5" vs-sm="11" vs-xs="11" class="py-3">
                  <vs-select v-model="criteria.criteria" :name="'criteria.'+index" class="w-full" data-vv-as="criteria" v-validate="'required'">
                    <vs-select-item :key="key" :value="item.value" :text="item.title" v-for="(item, key) in defaultCriteria(criteria.criteria)"/>
                  </vs-select>
                  <span class="text-danger text-sm py-3" v-show="errors.has('criteria.'+index)">{{ errors.first("criteria."+index) }}</span>
                  <div v-if="criteria.productField">
                    <ul class="mt-3">
                      <li v-for="(dropdownItem, key) in getDropdownData(criteria.productField)" :key="key" class="py-1">
                        <vs-checkbox v-model="criteria.dataList" :vs-value="dropdownItem" :name="'dataList.'+index" v-validate="'required'" data-vv-as="data list">{{dropdownItem}}</vs-checkbox>
                      </li>
                    </ul>
                    <span class="text-danger text-sm py-3" v-show="errors.has('dataList.'+index)">{{ errors.first("dataList."+index) }}</span>
                  </div>
                </vs-col>
                <vs-col vs-w="1" vs-xl="4" vs-md="2" vs-sm="1" vs-xs="1" class="md:hidden py-3">
                  <trash2-icon size="1.5x" class="button-icon cursor-pointer" @click="removeManualAssessmentCriteria(index)"></trash2-icon>
                </vs-col>
                <vs-col vs-w="11" vs-lg="4" vs-md="5" vs-sm="11" vs-xs="11" class="py-3">
                  <money
                    v-if="criteria.criteria == 'AMOUNT_GREATER_THAN'"
                    :name="'value.'+index"
                    :id="'value.'+index"
                    v-bind="money2"
                    v-validate="'required||min_value:1'"
                    v-model="criteria.value"
                    data-vv-as="amount"
                    class="w-full"
                  ></money>
                  <vs-select
                    v-if="criteria.criteria == 'DROPDOWN_HAS_VALUE'"
                    v-model="criteria.productField"
                    :name="'productField.'+index"
                    class="w-full"
                    data-vv-as="product field"
                    v-validate="'required'"
                  >
                    <vs-select-item v-for="(item, key) in getDropdownFields(criteria.productField)" :key="key" :value="item.label" :text="item.label" />
                  </vs-select>
                  <span class="text-danger text-sm py-3" v-show="errors.has('productField.'+index)">{{ errors.first('productField.'+index) }}</span>
                  <br>
                  <span class="text-danger text-sm py-3" v-show="errors.has('value.'+index)">{{ errors.first('value.'+index) }}</span>
                </vs-col>
                <vs-col vs-w="1" vs-xl="4" vs-md="2" vs-sm="1" vs-xs="1" class="hidden md:block py-3">
                  <trash2-icon size="1.5x" class="button-icon cursor-pointer" @click="removeManualAssessmentCriteria(index)"></trash2-icon>
                </vs-col>
              </vs-row>
            </div>
            <div v-if="showAddAssesmentCriteria">
              <plus-icon size="1.5x" class="button-icon cursor-pointer" @click="addManualAssessmentCriteria"></plus-icon>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>
    <!-- Manual Assessment Block Ends -->

    <!--------------- preview section -------------------------->
    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item>
          <div slot="header">
            <h3 class="font-normal">Payment page settings</h3>
          </div>
          <vs-row>
            <vs-col vs-w="10">
              <h5>Product card</h5>
            </vs-col>
            <vs-col vs-w="2" class="ds-wrapper">
              <a v-if="showProductCardResetButton" @click="resetPaymentCard" class="no-underline text-interaction-primary flex gap-4">
                <vs-icon icon-size="small" class="refresh-icon">refresh</vs-icon>
                Reset to default
              </a>
            </vs-col>
          </vs-row>
          <vs-divider></vs-divider>
          <div class="sm:grid-cols-1 md:grid-cols-2 preview-section my-10 h-auto">
            <div>
              <div>
                <label for="cardTitle" class="w-full text-xm font-normal">Card title</label>
                <vs-input size="large" v-model="product.cardTitle" data-vv-validate-on="blur" data-vv-as="card title" v-validate="'min:1|max:60'" name="cardTitle" id="cardTitle" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('cardTitle')">{{ errors.first("cardTitle") }}</span>
              </div>

              <div v-if="this.$route.query.productType === 'B2C_BNPL'">
                <label for="dateLabel" class="w-full text-xm font-normal">Date label</label>
                <vs-input size="large" v-model="product.dateLabel" data-vv-validate-on="blur" data-vv-as="date label" v-validate="'min:1'" name="dateLabel" id="dateLabel" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('dateLabel')">{{ errors.first("dateLabel") }}</span>
              </div>
              <div v-else>
                <label for="dateLabel" class="w-full text-xm font-normal">Message</label>
                <vs-input size="large" v-model="product.message" data-vv-validate-on="blur" data-vv-as="message" v-validate="'min:1'" name="message" id="message" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('message')">{{ errors.first("message") }}</span>
              </div>
              <div v-if="this.$route.query.productType !== 'B2C_BNPL'">
                <label for="feeLabel" class="w-full text-xm font-normal">Fee label</label>
                <vs-input size="large" v-model="product.feeLabel" data-vv-validate-on="blur" data-vv-as="fee label" v-validate="'min:1'" name="feeLabel" id="feeLabel" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('feeLabel')">{{ errors.first("feeLabel") }}</span>
              </div>
              <div v-if="this.$route.query.productType === 'B2C_BNPL'">
                <label for="amountLabel" class="w-full text-xm font-normal">Amount label</label>
                <vs-input size="large" v-model="product.amountLabel" data-vv-validate-on="blur" data-vv-as="amount label" v-validate="'min:1'" name="amountLabel" id="amountLabel" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('amountLabel')">{{ errors.first("amountLabel") }}</span>
              </div>
              <div v-else>
                <label for="purchaseAmountLabel" class="w-full text-xm font-normal">Purchase amount label</label>
                <vs-input size="large" v-model="product.purchaseAmountLabel" data-vv-validate-on="blur" data-vv-as="purchase amount label" v-validate="'min:1|max:20'" name="purchaseAmountLabel" id="purchaseAmountLabel" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('purchaseAmountLabel')">{{ errors.first("purchaseAmountLabel") }}</span>
              </div>
              <div class="mb-10">
                <label for="totalAmountLabel" class="w-full text-xm font-normal">Total amount label</label>
                <vs-input size="large" v-model="product.totalAmountLabel" data-vv-validate-on="blur" data-vv-as="total amount label" v-validate="'min:1|max:20'" name="totalAmountLabel" id="totalAmountLabel" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('totalAmountLabel')">{{ errors.first("totalAmountLabel") }}</span>
              </div>
              <div v-if="this.$route.query.productType === 'B2C_BNPL' && product.feeModel === 'MSF_ADDED'">
                <label for="feeTextLabel" class="w-full text-xm font-normal">Fee text</label>
                <div class="available-content-grid w-6/12 ds-wrapper mb-4">
                  <div @click="addText('{{Fee %}}')"><vs-chip color="primary" size="medium" variant="outlined" class="cursor-pointer">Fee %</vs-chip></div>
                  <div @click="addText('{{Fee $ amount}}')"><vs-chip color="primary" size="medium" variant="outlined" class="cursor-pointer">Fee $ amount</vs-chip></div>
                </div>
                <vs-textarea v-model="product.feeText" name="feeText" data-vv-as="fee text" ref="feeTextEditor" v-validate="'required'" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('feeText')">{{ errors.first("feeText") }}</span>
              </div>
            </div>

            <div style="max-width:435px;">
              <h5 class="block text-base mb-4">Preview</h5>
              <div class="preview pay-later-product-preview">
                <div class="previewed-item">
                  <div class="paylater-card">
                    <div class="card-title text-xl py-4 px-6 h-auto">{{ product.cardTitle }}</div>
                    <div class="card-details" v-if="this.$route.query.productType === 'B2C_BNPL'">
                      <div class="icon-group">
                        <vs-row class="flex justify-around px-6 py-4 items-center">
                          <div class="flex flex-col justify-center items-center">
                            <img :src="update" alt="update" />
                            <span class="dark-blue text-xs mt-1"> Every</span>
                            <span class="dark-blue text-xs mb-0">{{ payable }}</span>
                          </div>
                          <div class="flex flex-col justify-center items-center">
                            <img :src="playCircle" alt="playcircle" />
                            <span class="dark-blue text-xs mt-1">Starts</span>
                            <span class="dark-blue text-xs mb-0">{{ formatScheduleDate(schedule[0]) }}</span>
                          </div>
                          <div class="flex flex-col justify-center items-center">
                            <img :src="stopCircle" alt="stopcircle" />
                            <span class="dark-blue text-xs mt-1"> Ends after </span>
                            <span class="text-center text-xs dark-blue mb-0">{{ product.noOfPayments }} payments</span>
                          </div>
                        </vs-row>
                      </div>
                      <div class="w-full px-6 pb-6 pt-2">
                        <vs-row vs-w="12">
                          <vs-table :data="schedule" class="w-full summary-table">
                            <template slot="thead">
                              <vs-th><span class="text-base font-medium">{{ this.product.dateLabel }}</span></vs-th>
                              <vs-th class="sec-row-payment"><span class="text-base font-medium">{{ product.amountLabel }}</span></vs-th>
                            </template>
                            <template slot-scope="{ data }">
                              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td :data="data[indextr].value" class="font-light text-base">{{ formatScheduleDate(data[indextr]) }}</vs-td>
                                <vs-td :data="data[indextr].label" class="sec-row-payment font-light text-base">${{ perInstallmentAmount }}</vs-td>
                              </vs-tr>
                            </template>
                          </vs-table>
                        </vs-row>
                        <vs-divider class="my-2"></vs-divider>
                        <div class="total">
                          <div class="w-full flex justify-between">
                            <p class="text-lg dark-blue">{{ product.totalAmountLabel }}</p>
                            <p class="text-lg dark-blue">$ {{ addZeroes("1000") }}</p>
                          </div>
                          <p class="mt-2 text-sm" v-if="this.product.feeModel === 'MSF_ADDED'">{{ parseContent(product.feeText) }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="card-details" v-else>
                      <div class="flex flex-col m-auto">
                        <div class="grey-container py-4">
                          <p class="dark-blue text-xs text-center">{{ product.message }}</p>
                          <div class="flex items-center m-auto mt-3 justify-center">
                            <calendar-icon size="1.5x"></calendar-icon>
                            <span class="dark-blue ml-2">{{ getMaturityDate() }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="agreement-cont">
                        <div class="flex justify-between m-auto pb-2">
                          <span class="text-base"> {{ product.purchaseAmountLabel }}</span>
                          <span class="text-base"> $ {{ addZeroes("1000") }}</span>
                        </div>
                        <div class="flex justify-between m-auto py-5" v-if="this.product.feeModel === 'MSF_ADDED'">
                          <span class="text-base">{{ product.feeLabel }} ({{ product.msf }}%)</span>
                          <span class="text-base">${{ addZeroes(this.product.msf * 0.01 * 1000) }}</span>
                        </div>
                        <vs-divider class="m-auto my-5"></vs-divider>
                        <div class="total">
                          <div class="flex justify-between m-auto">
                            <p class="text-xl dark-blue">{{ product.totalAmountLabel }}</p>
                            <p class="text-xl dark-blue">${{ this.product.feeModel === "MSF_ADDED" ? addZeroes(this.product.msf * 0.01 * 1000 + 1000) : addZeroes(1000) }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <vs-row>
            <vs-col vs-w="10">
              <h5>Payment method</h5>
            </vs-col>
            <vs-col vs-w="2" class="ds-wrapper">
              <a @click="resetPaymentMethod" v-if="showPaymentPageResetButton" class="no-underline text-interaction-primary flex gap-4">
                <vs-icon icon-size="small" class="refresh-icon">refresh</vs-icon>
                Reset to default
              </a>
            </vs-col>
          </vs-row>
          <vs-divider></vs-divider>
          <div class="sm:grid-cols-1 md:grid-cols-2 preview-section my-10 h-auto">
            <div>
              <div>
                <label for="cardTitle" class="w-full text-xm font-normal">Section heading</label>
                <vs-input size="large" v-model="paymentPage.paymentSectionHeading" data-vv-validate-on="blur" data-vv-as="section heading" v-validate="'required|min:1|max:60'" name="paymentSectionHeading" id="paymentSectionHeading" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('paymentSectionHeading')">{{ errors.first("paymentSectionHeading") }}</span>
              </div>
              <div>
                <div class="flex ds-wrapper">
                  <label for="dateLabel" class="w-full text-xm font-normal">Message</label>
                  <vs-switch v-model="paymentPage.showPaymentMethodDescription" class="ml-8" />
                </div>
                <div v-if="paymentPage.showPaymentMethodDescription">
                  <vs-textarea
                    counter="250"
                    maxlength="250"
                    v-model="paymentPage.paymentMethodDescription"
                    name="paymentMethodDescription"
                    v-validate="paymentPage.showPaymentMethodDescription ? 'required|max:250' : ''"
                    data-vv-as="message"
                  />
                  <span class="text-danger text-sm block py-3" v-show="errors.has('paymentMethodDescription')">{{ errors.first("paymentMethodDescription") }}</span>
                </div>
              </div>
            </div>

            <div style="max-width:435px;">
              <h5 class="block text-base mb-4">Preview</h5>
              <div class="preview pay-later-product-preview">
                <div class="previewed-item">
                  <div class="paylater-card">
                    <vs-col vs-md="12" vs-sm="12">
                      <div class="mb-4">
                        <h2 class="text-lg lg:text-xl font-normal text-dark-blue">{{ paymentPage.paymentSectionHeading }}</h2>
                      </div>
                      <p class="mb-8">{{ paymentPage.paymentMethodDescription }}</p>
                    </vs-col>
                    <vs-col vs-md="6" vs-sm="12" class="max-w-420">
                      <div class="flex flex-row justify-center gap-4 ds-wrapper">
                        <div class="w-1/2 md:w-2/5">
                          <vs-chip class="w-full tabs-chip chip-active" color="neutral-150" size="large" variant="outlined" type="multiline">
                            <CardPayIcon class="chip-icon" />
                            <div class="chip-text">Card</div>
                          </vs-chip>
                        </div>
                        <div class="w-1/2 md:w-2/5">
                          <vs-chip class="w-full tabs-chip" color="neutral-150" size="large" variant="outlined" type="multiline">
                            <DirectDebitIcon class="chip-icon" />
                            <div class="chip-text">Direct debit</div>
                          </vs-chip>
                        </div>
                      </div>
                    </vs-col>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <!--------------- legals section -------------------------->
    <vx-card class="accordin-card mt-5 legals" id="legals">
      <vs-collapse accordion>
        <vs-collapse-item open ref="legalsCollapse">
          <div slot="header">
            <h3 class="font-normal">Legals <span class="mid-blue text-xl align-top" >*</span></h3>
          </div>
          <p class="block mb-10">Please upload relevant legal documents for this product.</p>
          <div class="legals__item">
            <h5 class="font-medium mb-2 text-xl">Customer Terms & Conditions</h5>
            <hr class="line-hr mb-8" />
            <p class="block my-5">Create the T&Cs customers must accept on the payment page when making payments for this product.</p>
            <div class="flex flex-row" :class="{ 'justify-end': customerTermsAndConditions.length > 0 }">
              <vs-button v-round color="primary" icon="add" @click="createProductLegal('CUSTOMER')" v-if="customerTermsAndConditions.length === 0">
                Create document
              </vs-button>
              <vs-button v-round class="only-border-btn mb-4" size="medium" @click="createProductLegal('CUSTOMER', true)" v-else>
                <plus-icon size="1.5x" class="button-icon"></plus-icon> New version
              </vs-button>
            </div>

            <vs-table :data="customerTermsAndConditions" v-if="customerTermsAndConditions.length > 0">
              <template slot="thead">
                <vs-th class="legals-th">Document name</vs-th>
                <vs-th class="legals-th">Date created</vs-th>
                <vs-th class="legals-th">Version</vs-th>
                <vs-th></vs-th>
                <vs-th></vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].documentName">{{ data[indextr].documentName }}</vs-td>
                  <vs-td :data="data[indextr].createdAt">{{ formatScheduleDate(data[indextr].createdAt) }}</vs-td>
                  <vs-td :data="data[indextr].id">{{ data[indextr].version }}</vs-td>
                  <vs-td :data="data[indextr].id"><span class="active-badge" v-if="indextr === 0">Active</span></vs-td>
                  <vs-td :data="data[indextr].id" class="view-btn"><a class="underline" v-if="checkLegalId(tr._id)" @click="viewLegal(tr._id)">view</a></vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>

          <div class="legals__item">
            <div class="flex justify-between">
              <h5 class="font-medium mb-2 text-xl">Merchant Terms & Conditions</h5>
              <vs-switch color="#0f67f4" v-model="product.isMerchantLegalActive"/>
            </div>
            <hr class="line-hr mb-8" />
            <div v-if="product.isMerchantLegalActive">
              <p class="block my-5">Create the T&Cs merchants must accept when enabling this product on their account.</p>
              <div class="flex flex-row" :class="{ 'justify-end': merchantTermsAndConditions.length > 0 }">
                <vs-button v-round color="primary" icon="add" @click="createProductLegal('MERCHANT')" v-if="merchantTermsAndConditions.length === 0">Create document</vs-button>
                <vs-button class="only-border-btn mb-4" v-round size="medium" @click="createProductLegal('MERCHANT', true)" v-else>
                  <plus-icon size="1.5x" class="button-icon"></plus-icon> New version
                </vs-button>
              </div>

              <vs-table :data="merchantTermsAndConditions" v-if="merchantTermsAndConditions.length > 0" class="mb-5">
                <template slot="thead">
                  <vs-th class="w-2/12 legals-th">Document name</vs-th>
                  <vs-th class="w-2/12 legals-th">Date created</vs-th>
                  <vs-th class="legals-th">Version</vs-th>
                  <vs-th></vs-th>
                  <vs-th></vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td class="capitalize" :data="data[indextr].documentName">{{ data[indextr].documentName }}</vs-td>
                    <vs-td :data="data[indextr].createdAt">{{ formatScheduleDate(data[indextr].createdAt) }}</vs-td>
                    <vs-td :data="data[indextr].id">{{ data[indextr].version }}</vs-td>
                    <vs-td :data="data[indextr].id"><span class="active-badge" v-if="indextr === 0">Active</span></vs-td>
                    <vs-td :data="data[indextr].id" class="view-btn"><a class="underline" v-if="checkLegalId(tr._id)" @click="viewLegal(tr._id)">view</a></vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
            <span class="text-danger text-sm block py-3" v-show="errors.has('legals')">{{ errors.first("legals") }}</span>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>
    <!--------------- Integration guide section -------------------------->
    <vx-card class="accordin-card mt-5" id="integrationBox">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header"><h3 class="font-normal">Integration guide<span class="mid-blue text-xl align-top">*</span></h3></div>
          <div class="flex flex-row" v-if="!checkIntegrationGuide.length">
            <vs-button v-round color="primary" icon="add" @click="createIntegrationGuide()">New article</vs-button>
          </div>
          <vs-table v-else :data="checkIntegrationGuide" class="mt-2">
            <template slot="thead">
              <vs-th width="400px">Article name</vs-th>
              <vs-th>Last updated</vs-th>
              <vs-th></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr>
                <vs-td>{{ integrationGuide.articleName }}</vs-td>
                <vs-td>{{ formatIntegrationDate(integrationGuide.createdAt) }}</vs-td>
                <vs-td class="text-right"><u><a @click="handleIntegrationGuideEdit()">Edit</a></u></vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <!-- Default communications -->
    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Default communications</h3>
          </div>
          <div class="grid grid-cols-3">
            <div class="flex mb-4">
              <p class="mr-8">D1</p>
              <vs-switch v-model="product.creditDefaultCommunication.d1" size="medium" />
            </div>
            <div class="flex mb-4">
              <p class="mr-8">D2</p>
              <vs-switch v-model="product.creditDefaultCommunication.d2" size="medium" />
            </div>
            <div class="flex mb-4">
              <p class="mr-8">D3</p>
              <vs-switch v-model="product.creditDefaultCommunication.d3" size="medium" />
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>
    <!-- Default communications Ends -->

    <!--------------- Communication section -------------------------->
    <vx-card class="accordin-card mt-5 legals" id="Communication" v-if="$route.query.id">
      <vs-collapse accordion>
        <vs-collapse-item open ref="communicationCollapse">
          <div slot="header"><h3 class="font-normal">Communications</h3></div>
          <h5 class="font-medium text-xl" id="email-template">Email</h5>
          <vs-table :data="emailTemplates" class="mt-2">
            <template slot="thead">
              <vs-th width="400px">Template type</vs-th>
              <vs-th>ID</vs-th>
              <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.templateCategory">{{ tr.templateCategory }}</vs-td>
                <vs-td :data="tr.templateId">{{ tr.templateId }}</vs-td>
                <vs-td :data="tr" class="text-right"><u><a @click="handleTemplateEdit(tr._id, 'Email')">Edit</a></u></vs-td>
              </vs-tr>
            </template>
          </vs-table>

          <h5 class="font-medium text-xl mt-5">Sms</h5>

          <vs-table :data="smsTemplates" class="mt-2">
            <template slot="thead">
              <vs-th width="400px">Template type</vs-th>
              <vs-th>ID</vs-th>
              <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.templateCategory">{{ tr.templateCategory }}</vs-td>
                <vs-td :data="tr.templateId">{{ tr.templateId }}</vs-td>
                <vs-td :data="tr" class="text-right"><u><a @click="handleTemplateEdit(tr._id, 'SMS')">Edit</a></u></vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <pop-up :errorsList="errors" form-name="PayLaterForm"></pop-up>

    <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>

    <vs-row class="mt-5 flex justify-end items-center">
      <vs-button class="text-sm" size="large" v-round :disabled="!validateForm" @click="prepareSubmit" color="primary">Save</vs-button>
      <a class="text-base flex items-center underline mid-blue ml-8" @click="$router.go(-1)">Cancel</a>
    </vs-row>
  </div>
</template>

<script>
import RadioBox from "@/views/components/radiobox";
import { Money } from "v-money";
import moment from "moment";
import slugify from "slugify";
import _ from "lodash";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import CustomFields from "@/views/components/CustomField";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Textarea from "@/views/forms/form-elements/textarea/Textarea";
import { RotateCcwIcon } from "vue-feather-icons";
import { PlusIcon, CalendarIcon, Trash2Icon } from "vue-feather-icons";
import { addDays, addMonths, format } from "date-fns";
import { Validator } from "vee-validate";
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import CardPayIcon from "../../../components/design-sys-components/icons/CardPayIcon";
import DirectDebitIcon from "../../../components/design-sys-components/icons/DirectDebitIcon";

const update = require("@/assets/images/update.png");
const playCircle = require("@/assets/images/play-circle.png");
const stopCircle = require("@/assets/images/stop-circle.png");
const questionIcon = require("@/assets/images/toolbox.svg");
const defaultSectionHeading = "Payment method";

export default {
  name: "PayLaterProduct",
  components: {
    RotateCcwIcon,
    Textarea,
    RadioBox,
    Money,
    PopUp,
    LeavePopup,
    CustomFields,
    CalendarIcon,
    PlusIcon,
    Trash2Icon,
    'popper': Popper,
    CardPayIcon,
    DirectDebitIcon,
  },
  data() {
    return {
      showField: true,
      tillConnectors: [],
      id: "",
      toggleFlex: false,
      defaultExtraConditionsCriteria: [
        {
          text: "Max % of product field",
          value: "MAX_PERCENT_OF_PRODUCT_FIELD",
        },
        {
          text: "Product field as due date",
          value: "PRODUCT_FIELD_AS_DUE_DATE",
        }
      ],
      extraConditionsList: [],
      customFieldBind: true,
      isSaved: false,
      parsedLegals: [],
      popupActive: false,
      customFieldList: [],
      nextObj: "", // determines the next route,
      money2: {
        precision: 0,
        prefix: "$ ",
      },
      money3: {
        precision: 0
      },
      frequencyInterval: {
        daily: 1,
        weekly: 7,
        fortnightly: 14,
        monthly: 30,
      },
      money: {
        precision: 2,
        suffix: "%",
      },
      numeric: {
        precision: 0,
      },
      active_el: true,
      product: {
        productName: "",
        isMerchantLegalActive: true,
        productDescription: "",
        feeLabel: "Fee",
        feeModel: "MSF_INCLUDED",
        productType: "",
        msf: "",
        legals: [],
        integrationGuide: {},
        customFields: [],
        multiplePayments: false,
        initialPeriod: 0,
        customerType: "CONSUMER",
        maxDuration: 0,
        productText: "",
        noOfPayments: 0,
        paymentFrequency: "DAILY",
        flipConnector: "",
        settlementAccount: "test",
        amountLabel: "Amount",
        totalAmountLabel: "Total",
        purchaseAmountLabel: "Purchase amount",
        message: "Our share of proceeds will be deducted on",
        cardTitle: "Your payment",
        dateLabel: "Payment Date",
        feeText: "Your payments include a {{Fee %}} service fee",
        extraConditions: [],
        gracePeriod: 0,
        isManualAssessment: false,
        manualAssessmentEmail: "",
        criteriaAssessmentEmail: "",
        productConfigType: "THREE_PARTY",
        autoDebit: true,
        paymentMethods: {
          paymentPage: ["CARD"],
          customerPortal: ["CARD"],
        },
        dishonourFee: 0,
        isDishonourFeeActive: false,
        creditDefaultCommunication: {
          d1: true,
          d2: true,
          d3: true,
        }
      },
      paymentPage: {
        paymentMethodDescription: "",
        showPaymentMethodDescription: false,
        paymentSectionHeading: defaultSectionHeading,
      },
      settlementAccounts: [],
      update: update,
      playCircle: playCircle,
      stopCircle: stopCircle,
      questionIcon: questionIcon,
      frequency: [
        { text: "Daily", value: "DAILY" },
        { text: "Weekly", value: "WEEKLY" },
        { text: "Fortnightly", value: "FORTNIGHTLY" },
        { text: "Monthly", value: "MONTHLY" },
      ],
      emailTemplates: [],
      smsTemplates: [],
      isBNPO: false,
      criteriaItem: {
        criteria: "",
        productField: "",
        dataList: []
      },
      manualAssessmentCriteria: [],
      defaultCriteriaData: [
        {
          title: "Request amount is greater than:",
          value: "AMOUNT_GREATER_THAN"
        },
        {
          title: "Dropdown field has the value of:",
          value: "DROPDOWN_HAS_VALUE"
        }
      ],
      productEdit: false,
      planReady: false,
      integrationGuide: {},
    };
  },
  computed: {
    ...mapGetters("paymentPlans", ["getCurrentExtraConditions", "getCurrentManualAssessmentCriteria", "getCurrentPaymentPlan", "getPaymentMethod"]),
    schedule() {
      return this.getSchedule(this.product.noOfPayments, this.product.paymentFrequency);
    },

    checkIntegrationGuide () {
      return Object.keys(this.integrationGuide);
    },

    perInstallmentAmount() {
      return this.truncate(1000 / this.product.noOfPayments);
    },

    payable() {
      let frequency = this.product.paymentFrequency;
      let payable = "";

      if (frequency === "WEEKLY") {
        payable = "1 week";
      } else if (frequency === "FORTNIGHTLY") {
        payable = "2 weeks";
      } else if (frequency === "MONTHLY") {
        payable = "1 month";
      } else if (frequency === "DAILY") {
        payable = "1 day";
      }
      return payable;
    },

    customerTermsAndConditions() {
      const terms = this.parsedLegals.filter((legal) => legal.user === "CUSTOMER");
      return _.sortBy(terms, ["createdAt"]).reverse();
    },

    merchantTermsAndConditions() {
      const terms = this.parsedLegals.filter((legal) => legal.user === "MERCHANT");
      return _.sortBy(terms, ["createdAt"]).reverse();
    },

    maxValidation() {
      if (this.product.paymentFrequency === "DAILY") {
        return "required|min_value:2|max_value:62";
      } else if (this.product.paymentFrequency === "WEEKLY") {
        return "required|min_value:2|max_value:8";
      } else if (this.product.paymentFrequency === "FORTNIGHTLY") {
        return "required|min_value:2|max_value:5";
      } else {
        return "required|min_value:2|max_value:3";
      }
    },

    getDropdownFields: function () {
      return function (field) {
        const selectedField = this.manualAssessmentCriteria.map(item => item.productField != field ? item.productField : null).filter(item => item);
        let dropdownFields = this.customFieldList.filter(item=> item.type === "dropdown" && !selectedField.includes(item.label));

        dropdownFields = dropdownFields.map(item => {
          return {
            dropdownSelectFields: item.dropdownSelectFields,
            label: item.label
          }
        })

        return dropdownFields;
      };
    },

    productFieldsForExtraConditions: function () {
      return function (field) {
        let dropdownFields = this.customFieldList.filter(item => {
          if (field == "MAX_PERCENT_OF_PRODUCT_FIELD") {
            return item.type == "currency";
          } else if (field == "PRODUCT_FIELD_AS_DUE_DATE") {
            return item.type == "date" && item.reqToSendRequest == true;
          }
        });

        dropdownFields = dropdownFields.map(item => {
          return {
            text: item.label,
            value: item.customFieldId
          }
        })

        return dropdownFields;
      };
    },

    defaultCriteria: function () {
      return function (option) {
        const amountGreaterCriteria = this.manualAssessmentCriteria.find(item => item.criteria == "AMOUNT_GREATER_THAN");

        if ((amountGreaterCriteria && option == "AMOUNT_GREATER_THAN") || !amountGreaterCriteria ) {
          return this.defaultCriteriaData;
        }
        return this.defaultCriteriaData.filter(item => item.value != "AMOUNT_GREATER_THAN");
      };
    },

    showAddAssesmentCriteria() {
      return (this.manualAssessmentCriteria.length <= this.customFieldList.filter(item => item.type === "dropdown").length);
    },

    extraConditionsCriteria: function () {
      return function (option) {
        const selectedConditions = this.extraConditionsList.map(item => item.criteria != option && item.criteria).filter(item => item);
        let extraConditionsCriteria = this.defaultExtraConditionsCriteria.filter(item => !selectedConditions.includes(item.value));

        if (this.product.productType == "B2C_BNPL") {
          extraConditionsCriteria = extraConditionsCriteria.filter(item => item.value != "PRODUCT_FIELD_AS_DUE_DATE");
        }

        return extraConditionsCriteria;
      };
    },

    showAddExtraConditionOption() {
      return this.extraConditionsList.length < (this.product.productType == "B2C_BNPL" ? this.defaultExtraConditionsCriteria.length - 1 : this.defaultExtraConditionsCriteria.length);
    },

    showPaymentPageResetButton() {
      return this.paymentPage.paymentSectionHeading != defaultSectionHeading || this.paymentPage.showPaymentMethodDescription || this.paymentPage.paymentMethodDescription;
    },

    showProductCardResetButton() {
      let display = this.product.totalAmountLabel != "Total";

      if (this.$route.query.productType == "B2C_BNPL") {
        display = this.product.cardTitle != "Your payment" || this.product.amountLabel != "Amount" || this.product.dateLabel != "Payment Date";
      } else {
        display = this.product.cardTitle != "Your agreement" || this.product.feeLabel != "Fee" || this.product.purchaseAmountLabel != "Purchase amount" || this.product.message != "Our share of proceeds will be deducted on";
      }

      return display;
    },

    showDishonourBox() {
      const directDebit = this.product.paymentMethods.customerPortal.includes("DIRECT_DEBIT");
      const autoDebit = this.product.autoDebit;

      return (autoDebit && directDebit);
    },

    showDishonourFee() {
      return (this.showDishonourBox && this.product.isDishonourFeeActive);
    },

    calculateFees() {
      let calculatedFee = this.product.msf * 0.01 * 1000;

      if (this.product.minimumMsfFeeAmount > calculatedFee) {
        calculatedFee = this.product.minimumMsfFeeAmount;
      }

      return calculatedFee;
    },
  },

  methods: {
    ...mapActions("flipBankAccounts", ["fetchAllFlipBankAccounts"]),
    ...mapActions("connector", ["getAllAdminConnectors"]),
    ...mapActions("paymentPlans", ["createPlan", "updatePlan"]),
    ...mapMutations("paymentPlans", ["SET_CURRENT_PLAN", "SET_CURRENT_MANUAL_ASSESSMENT_CRITERIA", "UNSET_CURRENT_MANUAL_ASSESSMENT_CRITERIA", "SET_EXTRA_CONDITIONS", "UNSET_EXTRA_CONDITIONS", "SET_PAYMENT_METHOD_INFO", "UNSET_PAYMENT_METHOD_INFO"]),
    ...mapActions("setting", ["fetchSetting"]),

    getSchedule(numberOfPayments, paymentFrequency) {
      const schedules = [];
      const now = new Date(); // current date
      for (let i = 0; i < numberOfPayments; i++) {
        let paymentSchedule;

        switch (paymentFrequency.toLowerCase()) {
          case "weekly":
            paymentSchedule = addDays(now, i * this.frequencyInterval.weekly);
            break;
          case "fortnightly":
            paymentSchedule = addDays(now, i * this.frequencyInterval.fortnightly);
            break;
          case "monthly":
            paymentSchedule = addMonths(now, i);
            break;
          default:
            paymentSchedule = addDays(now, i * this.frequencyInterval.daily);
        }

        schedules.push(paymentSchedule.toISOString());
      }
      return schedules;
    },

    formatScheduleDate(date) {
      return moment(date).format("DD MMM YYYY");
    },

    pushToCustomFields(customFieldList) {
      this.$refs.productFieldCollapse.maxHeight = "none !important";
      this.customFieldList = customFieldList;
      this.productFieldsForExtraConditions = this.getProductFieldsForExtraConditions();

      if (this.manualAssessmentCriteria && this.manualAssessmentCriteria.length) {
        this.manualAssessmentCriteria.forEach((item, index)=> {
          let checkProductFieldExists = this.customFieldList.find(value => value.label == item.productField);

          if (!checkProductFieldExists) {
            this.manualAssessmentCriteria[index].productField = '';
          }
        });
      }

      if (this.extraConditionsList && this.extraConditionsList.length) {
        this.extraConditionsList.forEach((item, index)=> {
          let checkProductFieldExists = this.customFieldList.find(value =>  value.customFieldId == item.productField);

          if (!checkProductFieldExists) {
            this.extraConditionsList[index].productField = '';
          }
        });
      }
    },

    checkLegalId(id) {
      return (id.includes("LG")) ? false : true;
    },

    viewLegal(id) {
      this.SET_CURRENT_PLAN(this.product);
      this.SET_CURRENT_MANUAL_ASSESSMENT_CRITERIA(this.manualAssessmentCriteria);
      this.SET_EXTRA_CONDITIONS(this.extraConditionsList);
      this.SET_PAYMENT_METHOD_INFO(this.paymentPage);

      const routeData = this.$router.resolve({
        name: "pay-later-legal-view",
        query: { type: "PRODUCT-LEGAL", fullDocument: false },
        params: { id },
      });

      window.open(routeData.href, '_blank');
    },

    createProductLegal(type, newVersion = false) {
      this.product.customFields = this.customFieldList;
      this.SET_CURRENT_PLAN(this.product);
      this.SET_CURRENT_MANUAL_ASSESSMENT_CRITERIA(this.manualAssessmentCriteria);
      this.SET_EXTRA_CONDITIONS(this.extraConditionsList);
      this.SET_PAYMENT_METHOD_INFO(this.paymentPage);

      let query = {};
      query.productType = this.$route.query.productType;
      query.type = type;
      query.newVersion = newVersion;

      if (this.$route.query.id) {
        query.id = this.$route.query.id;
      }

      this.$router.push({ name: "pay-later-legals", query: query });
    },

    createIntegrationGuide() {
      this.product.customFields = this.customFieldList;
      this.SET_CURRENT_PLAN(this.product);
      this.SET_CURRENT_MANUAL_ASSESSMENT_CRITERIA(this.manualAssessmentCriteria);
      this.SET_EXTRA_CONDITIONS(this.extraConditionsList);
      this.SET_PAYMENT_METHOD_INFO(this.paymentPage);

      let query = {};
      query.productType = this.$route.query.productType;

      if (this.$route.query.id) {
        query.id = this.$route.query.id;
      }

      this.$router.push({ name: "integration-guide", query: query});
    },

    getMaturityDate() {
      return this.formatScheduleDate(format(new Date(addDays(new Date(), this.product.initialPeriod)),"yyyy-MM-dd"));
    },

    addZeroes(num) {
      num = String(num);
      const dec = num.split(".")[1];
      return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2 });
    },

    async getTillConnectors() {
      await this.getAllAdminConnectors().then((result) => {
        this.tillConnectors = result.data.data;
      });
    },

    async getAllSettlementAccounts() {
      await this.fetchAllFlipBankAccounts().then((result) => {
        this.settlementAccounts = result.data.data.docs.filter((el) => el.tillConnectorDetails.length > 0);
      });
    },

    getPaymentPlanById(id) {
      let self = this;
      this.$vs.loading();
      this.$store.dispatch("paymentPlans/getAPlan", id).then((result) => {
        this.product = _.pick(result.data.data, Object.keys(self.product));

        if (this.product.flipConnector) {
          this.product.flipConnector = this.product.flipConnector._id;
        }

        this.product.msf = parseFloat(self.product.msf);
        this.customFieldList = result.data.data.customFields.map((field) => {
          return _.omit(field, ["slug"]);
        });

        if (this.getCurrentPaymentPlan) {
          this.product = { ...this.getCurrentPaymentPlan };
          this.customFieldList = this.product.customFields;
        }

        this.productFieldsForExtraConditions = this.getProductFieldsForExtraConditions();
        this.emailTemplates = result.data.data.emailTemplates;
        this.smsTemplates = result.data.data.smsTemplates;
        this.parsedLegals = this.product.legals;
        this.extraConditionsList = this.product.extraConditions;

        if (result.data.data.manualAssessmentCriteria && result.data.data.manualAssessmentCriteria.length) {
          this.manualAssessmentCriteria = result.data.data.manualAssessmentCriteria;
        }

        if (this.getCurrentManualAssessmentCriteria) {
          this.manualAssessmentCriteria = [ ...this.getCurrentManualAssessmentCriteria ];
        }

        if (this.getCurrentExtraConditions) {
          this.extraConditionsList = [ ...this.getCurrentExtraConditions ];
        }

        if (this.getPaymentMethod) {
          this.paymentPage = { ...this.getPaymentMethod };
        } else {
          this.paymentPage.paymentSectionHeading = result.data.data.paymentSectionHeading ? result.data.data.paymentSectionHeading : "";
          this.paymentPage.showPaymentMethodDescription = result.data.data.showPaymentMethodDescription ? result.data.data.showPaymentMethodDescription : false;
          this.paymentPage.paymentMethodDescription = result.data.data.paymentMethodDescription ? result.data.data.paymentMethodDescription : "";
        }

        if (this.product.integrationGuide) {
          this.integrationGuide = this.product.integrationGuide;
        }

        this.$vs.loading.close();
        this.planReady = true;
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showToastMessage("Error", ex.message, "error");
      });
    },

    truncate(num) {
      return Number(num).toFixed(3).slice(0, -1);
    },

    unsetStoreData() {
      this.UNSET_EXTRA_CONDITIONS();
      this.UNSET_CURRENT_MANUAL_ASSESSMENT_CRITERIA();
      this.UNSET_PAYMENT_METHOD_INFO();
    },

    popActions(event) {
      switch (event) {
        case "leave":
          this.nextObj();
          this.unsetStoreData();
          break;
        case "save":
          this.popupActive = false;
          this.unsetStoreData();
          this.prepareSubmit();
          break;
        case "cancel":
          this.popupActive = false;
          break;
        default:
          this.unsetStoreData();
          this.popupActive = false;
      }
    },

    changeDishonourFee() {
      if (!this.product.isDishonourFeeActive) {
        this.getDishonourFee();
      }
    },

    prepareSubmit() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {

          if (this.product.autoDebit && this.product.paymentMethods.paymentPage && this.product.paymentMethods.paymentPage.length == 0) {
            this.showToastMessage("Error", "Please add atleast one payment method", "error");
            return;
          }

          if (this.product.isDishonourFeeActive && this.product.dishonourFee == 0) {
            this.errors.add({
              field: "dishonourFee",
              msg: "Dishonour fee required."
            });

            return;
          }

          if (this.customerTermsAndConditions.length === 0) {
            this.showToastMessage("Error", "Please add atleast one customer T&C", "error");
            return;
          }

          if (this.product.isMerchantLegalActive && this.merchantTermsAndConditions.length === 0) {
            this.showToastMessage("Error", "Please add atleast one merchant T&C", "error");
            return;
          }

          if (!this.product.integrationGuide || Object.keys(this.product.integrationGuide).length === 0 ) {
            this.$vs.notify({
              title: "Error",
              text: "Please add integration guide",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
            return;
          }

          this.$vs.loading();
          this.product.minimumAmount = this.product.minimumAmount.toString();
          this.product.maximumAmount = this.product.maximumAmount.toString();
          this.product.maxDuration = this.product.maxDuration.toString();
          this.product.msf = this.truncate(this.product.msf);
          this.product.minimumMsfFeeAmount = this.product.minimumMsfFeeAmount.toString();
          this.product.customFields = JSON.stringify(this.customFieldList);
          this.product.legals = JSON.stringify(this.product.legals);
          this.product.extraConditions = JSON.stringify(this.extraConditionsList);
          this.product.paymentSectionHeading = this.paymentPage.paymentSectionHeading;
          this.product.showPaymentMethodDescription = this.paymentPage.showPaymentMethodDescription;
          this.product.paymentMethodDescription = this.paymentPage.paymentMethodDescription;
          this.integrationGuide = JSON.stringify(this.product.integrationGuide);

          if (this.product.productType == "B2C_BNPL" || !this.$route.query.id) {
            this.product.noOfPayments = this.product.noOfPayments.toString();
          }

          if (this.product.productType != "B2C_BNPL" || !this.$route.query.id) {
            this.product.initialPeriod = this.product.initialPeriod.toString();
          }

          const currentBank = this.settlementAccounts.find((el) => el._id === this.product.settlementAccount);

          this.product.flipConnector = currentBank.tillConnectorDetails.length > 0 ? currentBank.tillConnectorDetails[0]._id : "";

          this.customFieldList = this.customFieldList.map((el) => {
            el.slug = slugify(el.label);
            return el;
          });

          if (this.manualAssessmentCriteria.length) {
            this.product.manualAssessmentCriteria = JSON.stringify(this.manualAssessmentCriteria);
          }
          this.product.paymentMethods = JSON.stringify(this.product.paymentMethods);

          if (this.$route.query.id) {
            this.updatePaymentPlan();
          } else {
            this.addPaymentPlan();
          }
        }
      });
    },

    updatePaymentPlan() {
      this.updatePlan({
        id: this.$route.query.id,
        data: this.product,
      }).then((result) => {
        this.$vs.loading.close();
        this.isSaved = true;
        this.$vs.notify({
          title: "Success",
          text: "Paylater product updated successfully.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.$router.push({name: 'pay-later-plan-list'});
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: ex.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
    },

    addExtraCondition() {
      this.$refs.productExtraConditionCollapse.maxHeight = "none !important";
      let firstBits = Math.floor(1000 + Math.random() * 9000);
      let secondBits = Math.floor(1000 + Math.random() * 9000);
      let fieldId = `EF-${firstBits}-${secondBits}`;
      this.extraConditionsList.push({
        _id: fieldId,
        criteria: "",
        maxPercentage: 0,
        productField: "",
        errorMsg: "",
      });
    },

    removeExtraCondition(index) {
      this.extraConditionsList.splice(index, 1);
    },

    resetPaymentCard() {
      this.product.amountLabel = "Amount";
      this.product.totalAmountLabel = "Total";
      this.product.purchaseAmountLabel = "Purchase amount";
      this.product.message = "Our share of proceeds will be deducted on";
      this.product.cardTitle = this.$route.query.productType == "B2C_BNPL" ? "Your payment" : "Your agreement";
      this.product.dateLabel = "Payment Date";
    },

    resetPaymentMethod() {
      this.paymentPage.paymentSectionHeading = defaultSectionHeading;
      this.paymentPage.showPaymentMethodDescription = false;
      this.paymentPage.paymentMethodDescription = "";
    },

    addPaymentPlan() {
      this.createPlan(this.product).then((result) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Success",
          text: "Paylater product created successfully.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.isSaved = true;
        this.$router.push({name: 'pay-later-plan-list'});
      }).catch((ex) => {
        this.$vs.loading.close();
      });
    },

    getProductFieldsForExtraConditions() {
      let conditions = [];
      conditions = this.customFieldList
        .filter((element) => {
          return element.type == "currency";
        })
        .map((item) => {
          return { text: item.label, value: item.customFieldId };
        });
      return conditions;
    },

    handleTemplateEdit(id, templateType) {
      if (templateType === "Email") {
        return this.$router.push({
          name: "email-templates-edit",
          params: { id: id },
          query:{planId:this.$route.query.id, productType:this.$route.query.productType}
        });
      }

      if (templateType === "SMS") {
        return this.$router.push({
          name: "sms-templates-edit",
          params: { id: id },
          query:{planId:this.$route.query.id, productType:this.$route.query.productType}
        });
      }
    },

    handleIntegrationGuideEdit() {
      this.product.customFields = this.customFieldList;
      this.SET_CURRENT_PLAN(this.product);
      this.SET_CURRENT_MANUAL_ASSESSMENT_CRITERIA(this.manualAssessmentCriteria);
      this.SET_EXTRA_CONDITIONS(this.extraConditionsList);
      this.SET_PAYMENT_METHOD_INFO(this.paymentPage);

      let query = {};
      query.action = "edit";
      query.productType = this.$route.query.productType;

      if (this.$route.query.id) {
        query.id = this.$route.query.id;
      }
      return this.$router.push({ name: "integration-guide", query: query });
    },

    addManualAssessmentCriteria() {
      this.manualAssessmentCriteria.push(JSON.parse(JSON.stringify(this.criteriaItem)));
    },

    removeManualAssessmentCriteria(index) {
      this.manualAssessmentCriteria.splice(index, 1);
    },

    getDropdownData(label) {
      const data = this.customFieldList.find(item => item.label == label);
      return data ? data.dropdownSelectFields : [];
    },

    getBnplDuration(frequency) {
      let paymentFrequency = 1;
        switch(frequency) {
          case "WEEKLY":
            paymentFrequency = 7;
            break;
          case "FORTNIGHTLY":
            paymentFrequency = 14;
            break;
          case "MONTHLY":
            paymentFrequency = 30;
            break;
        };
      return paymentFrequency;
    },

    changeProductConfig(type) {
      if (!this.productEdit) {
        this.product.productConfigType = type;
      }
    },

    changePaymentMethods() {
      if (this.product.autoDebit) {
        this.product.paymentMethods = {
          paymentPage: ['CARD', 'DIRECT_DEBIT'],
          customerPortal: ['CARD', 'DIRECT_DEBIT', 'EFT']
        };
      } else {
        this.product.paymentMethods = {
          paymentPage: [],
          customerPortal: ['EFT']
        };
        if (this.errors.has(`paymentMethod`)) {
          this.errors.remove(`paymentMethod`);
        }
      }
    },

    validatePaymentMethods(key) {
      if (this.product.paymentMethods.paymentPage.includes(key)) {
        if (!this.product.paymentMethods.customerPortal.includes(key)) {
          this.product.paymentMethods.customerPortal.push(key);
        }
      } else {
        this.product.paymentMethods.customerPortal = this.product.paymentMethods.customerPortal.filter((item) => item != key);
      }

      if (this.product.autoDebit && this.product.paymentMethods.paymentPage && this.product.paymentMethods.paymentPage.length == 0) {
        this.errors.add({
          field: "paymentMethod",
          msg: "Please select at least one payment method"
        });
      } else {
        if (this.errors.has(`paymentMethod`)) {
          this.errors.remove(`paymentMethod`);
        }
      }
    },

    async getDishonourFee() {
      this.$vs.loading();

      await this.fetchSetting("dishonourFee").then((result) => {
        this.product.dishonourFee = result.data.data.rawAmount;
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showToastMessage(ex.response.data.title, ex.response.data.message, "error");
      });
    },

    checkLegalId(id) {
      return (id && id.includes("LG")) ? false : true;
    },

    formatIntegrationDate(date) {
      return moment(date).format("DD/MM/YYYY - hh:mm:ss");
    },

    addText(val) {
      const matches = this.product.feeText.match(/{{(.+?)}}/g);

      if (!matches || !matches.includes(val)) {
        this.insertText(val);
      }
    },

    parseContent(data) {
      if (!data) {
        return "";
      }

      data = data.split("{{Fee %}}").join(this.product.msf + "%");
      data = data.split("{{Fee $ amount}}").join("$"+this.addZeroes(this.calculateFees));

      return data;
    },

    insertText(text) {
      const el = this.$refs.feeTextEditor.$el.querySelector("textarea");
      let cursorPos = el.selectionEnd; // Get current Position
      this.product.feeText = this.product.feeText.substring(0, cursorPos) + text + this.product.feeText.substring(cursorPos);
      cursorPos += this.product.feeText.length; // Get new cursor position

      // Wait until vue finishes rendering the new text and set the cursor position.
      this.$nextTick(() => el.setSelectionRange(cursorPos, cursorPos));
    }
  },

  async mounted() {
    Validator.extend("greaterThan", {
      getMessage: (field) => `The maximum amount should be greater than minimum amount.`,
      validate: (value) => parseFloat(value) > parseFloat(this.product.minimumAmount),
    });

    if (this.$refs.legalsCollapse.maxHeight) {
      this.$refs.legalsCollapse.maxHeight = "none !important";
    }

    if (["B2C_BNPO", "B2C_BNPO_NC"].includes(this.$route.query.productType)) {
      this.product.cardTitle = "Your agreement";
      this.isBNPO = true
    } else {
      this.isBNPO = false
      this.product.cardTitle = "Your payment";
    }

    if (this.$route.query.productType) {
      this.product.productType = this.$route.query.productType;
    }

    this.product.maxDuration = 0;
    await this.getAllSettlementAccounts();

    if (this.getCurrentPaymentPlan && (this.$route.query.legal || this.$route.query.integrationGuide)) {
      this.product = { ...this.getCurrentPaymentPlan };
      this.parsedLegals = this.product.legals;
      this.customFieldList = this.product.customFields;

      if (this.product.integrationGuide) {
        this.integrationGuide = this.product.integrationGuide;
      }

      if (this.$route.query.legal === true) {
        const el = document.getElementById("legals");
        el.scrollIntoView({ behavior: "smooth", block: "end" });
      } else if (this.$route.query.integrationGuide === true) {
        const integrationElement = document.getElementById("integrationBox");
        integrationElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }

    if (this.$route.query.id) {
      this.productEdit = true;
      await this.getPaymentPlanById(this.$route.query.id);
    } else {
      if (!this.$route.query.type && !this.product.dishonourFee) {
        this.getDishonourFee();
      }

      this.planReady = true;
    }

    if (this.getCurrentManualAssessmentCriteria) {
      this.manualAssessmentCriteria = [ ...this.getCurrentManualAssessmentCriteria ];
    }

    if (this.getCurrentExtraConditions) {
      this.extraConditionsList = [ ...this.getCurrentExtraConditions ];
    }

    if (this.getPaymentMethod) {
      this.paymentPage = { ...this.getPaymentMethod };
    }

    if (this.$route.query.template) {
      const el = document.getElementById("email-template");
      el.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  },

  beforeRouteLeave(to, from, next) {
    if (!["integration-guide", "pay-later-legals"].includes(to.name)) {
      this.unsetStoreData();
    }
    next();
  },

  watch: {
    "product.initialPeriod": function (newVal, _oldVal) {
      if (["B2C_BNPO", "B2C_BNPO_NC"].includes(this.product.productType)) {
        this.product.maxDuration = newVal;
      }
    },
    "product.paymentFrequency": function () {
      if (["B2C_BNPL"].includes(this.product.productType)) {
        this.product.maxDuration = this.getBnplDuration(this.product.paymentFrequency) * this.product.noOfPayments;
      }
    },
    "product.noOfPayments": function () {
      if (["B2C_BNPL"].includes(this.product.productType)) {
        this.product.maxDuration = this.getBnplDuration(this.product.paymentFrequency) * this.product.noOfPayments;
      }
    },
    "product.autoDebit": function () {
      this.changePaymentMethods();
    },
    "paymentPage.showPaymentMethodDescription": function(val) {
      if (!val) {
        this.paymentPage.paymentMethodDescription = "";
      }
    },
    "product.paymentMethods.paymentPage" : function(val) {
      if (["DIRECT_DEBIT"].includes(val)) {
        this.product.isDishonourFeeActive = true;
      }
    }
  },
};
</script>

